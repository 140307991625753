body {
  font-family: Montserrat, sans-serif;
}

h1 {
  color: var(--wzb-bo-primary);
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1;
  margin: 0 0 1.25rem;
}

h2 {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1;
  margin: 0 0 1.25rem;
}

h3 {
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.8;
  margin: 0 0 1rem;
}

h4 {
  font-size: 1.125rem;
  font-weight: normal;
  line-height: 1.5;
  margin: 0 0 1rem;
}

h5 {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.4;
  margin: 0 0 1rem;
}

h6 {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.2;
  margin: 0 0 1rem;
}
