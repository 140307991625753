.ghost-btn {
  display: flex;
  align-items: center;
  height: 2.0625rem;
  position: relative;
  border-radius: 0.625rem;
  border: solid 0.0625rem var(--wzb-bo-blue-dodger);
  background-color: white;
  padding: 0.5rem 0.75rem;
  font-size: 0.75rem;
  line-height: 1.4;
  font-weight: 500;
  text-decoration: none;
  color: var(--wzb-bo-blue-dodger);
  transition: all 0.5 ease;

  &:hover {
    background-color: var(--wzb-bo-blue-dodger);
    color: white;
  }

  &:disabled {
    background-color: rgb(0 0 0 / 12%);
    border-color: rgb(0 0 0 / 26%);
    color: rgb(0 0 0 / 26%);
  }
}
