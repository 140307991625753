/* stylelint-disable-next-line selector-type-no-unknown */
mat-calendar {
  .mat-calendar-table {
    .mat-calendar-body-cell {
      .mat-calendar-body-cell-content {
        .mat-calendar-body-selected {
          background-color: var(--wzb-bo-primary) !important;
          color: white !important;
        }
      }
    }
  }
}
