/* stylelint-disable selector-max-specificity */
.mat-mdc-list-base.mat-mdc-selection-list[wrap] {
  .mdc-list-item__content {
    white-space: normal;

    .mdc-list-item__primary-text {
      white-space: normal;
    }
  }
}
