.widget__toggle-container__wzb-toggle .wzb-toggle-label {
  font-weight: bold;
}

/* stylelint-disable selector-no-qualifying-type */
/* stylelint-disable selector-type-no-unknown */

wzb-toggle[align-start] {
  justify-content: flex-start;
}
