@use '@angular/material' as mat;
@use 'material/variables' as variables;

@mixin color-chip($contextSelector, $selector) {
  #{$contextSelector}[data-color] #{$selector} {
    position: relative;
    padding-left: 1.375rem;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 0.625rem;
      height: 0.625rem;
      transform: translateY(-50%);
      border-radius: 50%;
      background-color: #92929d;
      transition: background-color 300ms cubic-bezier(0, 0, 0.2, 1);
    }
  }

  #{$contextSelector}[data-color='warn'] #{$selector}::before {
    background-color: mat.m2-get-color-from-palette(variables.$warn);
  }

  #{$contextSelector}[data-color='success'] #{$selector}::before {
    background-color: mat.m2-get-color-from-palette(variables.$success);
  }
}

.mat-form-field-type-mat-select.mat-form-field.mat-form-field-appearance-fill {
  @include color-chip('.mat-select', '.mat-select-trigger .mat-select-value .mat-select-value-text');
}

.mat-select-panel-wrap .mat-select-panel {
  @include color-chip('.mat-option', '.mat-option-text');
}
