// stylelint-disable selector-max-specificity, selector-no-qualifying-type, selector-type-no-unknown, no-descending-specificity, selector-max-type, max-nesting-depth
@use '@wizbii-components/bo-styles/src/colors/bo-colors.scss' as colors;
:root {
  --wzb-bo-side-menu-expanded-icon-width: 1.5rem;
}

.mat-accordion[multipleSideMenu] {
  .mat-expansion-panel {
    box-shadow: none;
    border-radius: unset;

    &:first-child {
      margin-top: 0.5rem;
    }

    .mat-expansion-panel-header {
      padding: 0.5rem 0;
      height: 100%;

      &-title {
        font-weight: 500;
        font-size: 0.8125rem;
        color: #242936;
        gap: 0.625rem;

        .icon {
          width: var(--wzb-bo-side-menu-expanded-icon-width);
          height: var(--wzb-bo-side-menu-expanded-icon-width);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          box-shadow: 0 0.25rem 0.5rem rgb(96 97 112 / 16%);
        }
      }

      &:hover {
        background-color: inherit !important;
      }

      .mat-expansion-indicator {
        display: none;
      }

      .mat-content {
        overflow: visible;
      }
    }

    .mat-expanded {
      .mat-expansion-panel-header {
        &-title {
          color: colors.$wzb-electric;

          .icon {
            background-color: colors.$wzb-electric;
            box-shadow: none;
          }
        }
      }
    }

    &[isEmpty='true'] {
      .mat-expansion-panel-content {
        display: none;
      }
    }
  }

  .mat-expansion-panel-spacing {
    margin: 0;
  }

  .mat-expansion-panel-body {
    padding: 0;
    display: flex;
    flex-direction: column;
    border-left: solid 2px #9697d830;
    padding-left: 1.3125rem;
    margin: 0.5rem 0 0.5rem 0.75rem;

    .item {
      color: #242936;
      text-decoration: none;
      font-weight: normal;
      font-size: 0.8125rem;
      padding-bottom: 1rem;
      margin: 0;

      &:last-of-type {
        padding: 0;
      }

      &--active {
        color: colors.$wzb-electric;
      }

      &--disabled {
        color: #6d7079;
      }
    }
  }
}
