/* stylelint-disable-next-line selector-not-notation */
.mat-accordion:not(.wzb-bo-menu):not([multiplesidemenu]) {
  .mat-expansion-panel {
    /* stylelint-disable-next-line unit-disallowed-list */
    box-shadow:
      0 0.1875rem 0.0625rem -0.125rem rgb(0 0 0 / 20%),
      0 0.125rem 0.125rem 0 rgb(0 0 0 / 14%),
      0 0.0625rem 0.3125rem 0 rgb(0 0 0 / 12%) !important;
  }
}
