@use '@angular/material' as mat;
@use 'material/variables' as variables;

/* stylelint-disable selector-no-qualifying-type, selector-type-no-unknown */
mat-dialog-container.mat-mdc-dialog-container {
  .mdc-dialog__container {
    .mat-mdc-dialog-surface {
      border-radius: 0.75rem;
      padding: 2rem;

      .mat-mdc-dialog-title {
        font-weight: 600;
        font-size: 1.25rem;
        text-align: center;
        padding: 0 0 1.25rem !important;
      }

      .dialog & {
        padding: 1.5rem;
        padding-bottom: 2rem;
      }

      .mat-mdc-dialog-content {
        text-align: center;
        margin-bottom: 1rem;
        padding-top: 0;
        padding-bottom: 0;
      }

      .mat-mdc-dialog-actions {
        display: flex;
        flex-flow: row nowrap;
        gap: 1rem;
        padding: 1rem 0 0 !important;

        /* stylelint-disable-next-line selector-max-specificity */
        .mat-dialog-actions__item {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
}

.mat-dialog--overflow {
  overflow-y: auto;
  max-height: calc(100vh - 3.75rem);
}
