.mat-checkbox-checked {
  .mat-checkbox-background {
    background-color: var(--wzb-bo-primary) !important;
  }

  .mat-checkbox-label {
    color: var(--wzb-bo-primary) !important;
  }
}

.mat-checkbox {
  background-color: #f4f4f4;
  padding: 1rem;
  display: block !important;
  border-radius: 8px;

  label {
    margin: 0;

    .mat-checkbox-inner-container {
      height: 1.25rem;
      width: 1.25rem;

      .mat-checkbox-frame {
        border-radius: 6px;
        border-color: #e5e6eb;
        background-color: white;
      }

      .mat-ripple-element {
        background-color: #5c59f2 !important;
      }
    }

    .mat-checkbox-label {
      color: #555;
    }
  }
}

.simple-checkbox.mat-checkbox {
  background-color: white;
  padding: 0 1rem 1rem 0;

  label {
    margin: 0;

    .mat-checkbox-label {
      font-size: 0.8125rem;
      line-height: 140%;
      font-weight: normal;
    }
    .mat-checkbox-inner-container {
      width: 1rem;
      height: 1rem;

      .mat-checkbox-background {
        border-radius: 4px;
      }
    }
  }
}
