/* stylelint-disable no-descending-specificity */
/* stylelint-disable max-nesting-depth */
/* stylelint-disable selector-max-specificity */
.mat-mdc-form-field.mat-mdc-form-field-type-mat-input.mat-form-field-appearance-fill {
  .mat-mdc-text-field-wrapper.mdc-text-field--filled {
    .mat-mdc-form-field-flex {
      .mat-mdc-form-field-infix {
        .mat-mdc-input-element {
          font-weight: normal;
          font-size: 0.875rem;
          line-height: 1.4;
          padding-top: 1.25rem;
          padding-bottom: 0.625rem;
          box-sizing: content-box;
          color: var(--wzb-bo-text-black);
        }
      }
    }
  }

  &.mat-form-field-disabled {
    .mat-mdc-text-field-wrapper.mdc-text-field--filled {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          .mat-mdc-input-element {
            color: var(--wzb-bo-text-disable);
          }
        }
      }
    }
  }
}
