/* stylelint-disable no-descending-specificity */
/* stylelint-disable max-nesting-depth */
/* stylelint-disable selector-max-specificity */
.mat-mdc-form-field.mat-form-field-appearance-fill {
  --height: 3rem;

  &.mat-mdc-form-field--auto-height {
    --height: auto;

    min-height: 3rem;

    .mat-mdc-form-field-flex {
      min-height: 3rem;
    }
  }

  width: 100%;

  .mat-mdc-text-field-wrapper.mdc-text-field--filled {
    background-color: white;
    border: 1px solid var(--wzb-bo-grey);
    border-radius: 0.625rem;
    padding: 0 1rem;
    transition: border-color 150ms;

    .mat-mdc-form-field-focus-overlay {
      display: none;
    }

    .mat-mdc-form-field-flex {
      height: var(--height);

      .mat-mdc-form-field-infix {
        padding: 0;
        width: 100%;
        min-height: var(--height);
        height: var(--height);
        /* stylelint-disable-next-line unit-disallowed-list */
        margin-top: -1px;

        .mdc-floating-label {
          font-weight: normal;
          font-size: 0.875rem;
          line-height: 1.4;
          margin: 0;
          transform: translateY(-70%);
          color: var(--wzb-bo-text-grey);

          &.mdc-floating-label--float-above {
            transform: translateY(-110%) scale(0.75);
            font-weight: 500;
            line-height: 1.4;
            color: var(--wzb-bo-primary);
          }

          .mat-mdc-form-field-required-marker {
            display: none;
          }
        }
      }

      .mat-mdc-form-field-icon-prefix {
        color: var(--wzb-bo-text-grey);
        height: var(--height);
        width: auto;

        .mat-icon {
          color: -var(-wzb-bo-text-grey);
          display: flex !important;
          align-items: center;
          justify-content: center;
          width: 1.25rem;
          height: var(--height);
          padding: 0 0.75rem 0 0;
        }
      }
    }
  }
  .mdc-text-field--no-label {
    .mat-mdc-form-field-flex {
      align-items: center;

      .chip-list {
        padding-top: unset;

        &__chevron-down {
          margin: auto;
        }
      }
    }
  }

  .mdc-line-ripple {
    display: none;
  }

  .mat-mdc-form-field-hint-spacer {
    flex: inherit !important;
  }

  .mat-mdc-form-field-hint-wrapper {
    padding: 0 !important;
  }

  &.mat-form-field-invalid {
    .mat-mdc-text-field-wrapper.mdc-text-field--filled {
      /* stylelint-disable-next-line unit-disallowed-list */
      box-shadow: 0 0 0 3px rgb(206 63 67 / 20%);
      border-color: var(--wzb-bo-invalid-red);
    }
  }

  &.mat-form-field-disabled {
    * {
      cursor: not-allowed;
    }

    .mat-mdc-text-field-wrapper.mdc-text-field--filled {
      border-color: var(--wzb-bo-grey);
      background-color: #f8f8f8;

      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          .mdc-floating-label {
            color: var(--wzb-bo-text-disable);

            &.mdc-floating-label--float-above {
              color: var(--wzb-bo-text-disable);
            }
          }
        }
      }
    }
  }

  &:not(.mat-form-field-hide-placeholder, .mat-form-field-invalid, .mat-form-field-disabled) {
    .mat-mdc-text-field-wrapper.mdc-text-field--filled {
      border-color: var(--wzb-bo-blue);
    }
  }
}

legend {
  margin-bottom: 1rem;
}
