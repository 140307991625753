/* stylelint-disable selector-no-qualifying-type */
/* stylelint-disable selector-type-no-unknown */
/* stylelint-disable selector-max-type */
/* stylelint-disable selector-max-specificity */

.clickable-rows {
  transition: box-shadow ease-in-out 300ms;
  &:hover,
  &:focus {
    box-shadow: var(--shadow-three);
    cursor: pointer;
  }
}

.mat-mdc-table {
  .mat-mdc-header-row {
    margin-bottom: 1.5rem !important;
  }

  .mat-mdc-header-row,
  .mat-mdc-row {
    border-color: #dcd9e3;

    .mat-mdc-cell {
      font-size: 0.8125rem;
      border: none;

      .mat-mdc-button.mat-mdc-button-base,
      .mat-mdc-raised-button.mat-mdc-button-base,
      .mat-mdc-unelevated-button.mat-mdc-button-base,
      .mat-mdc-outlined-button.mat-mdc-button-base {
        height: 2.0625rem;
      }
    }

    .mat-mdc-header-cell {
      font-size: 0.75rem;
    }
  }
}

wzb-bo-table[hasAction] {
  mat-cell {
    border-bottom-color: none;
  }

  mat-cell:last-of-type,
  mat-header-cell:last-of-type {
    flex: 0 1 1.5rem;
    margin-right: 1rem;
  }
}
