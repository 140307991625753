:root {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  :where(li, ul) {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  :where(img) {
    display: block;
  }

  :where(button) {
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }

  :where(a) {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  :where(fieldset) {
    border: none;
    padding: 0;
    margin: 0;
  }

  hr {
    border-top: 1px solid var(--wzb-bo-disabled);
    width: 100%;
    margin: 0;
  }

  /* stylelint-disable-next-line selector-no-qualifying-type */
  input[type='number']::-webkit-inner-spin-button,
  /* stylelint-disable-next-line selector-no-qualifying-type */
  input[type='number']::-webkit-outter-spin-button {
    display: none;
    margin: 0;
  }
}
