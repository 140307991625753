// stylelint-disable selector-max-specificity, selector-no-qualifying-type, selector-type-no-unknown, no-descending-specificity, selector-max-type
.wzb-ckeditor {
  .ck-editor__editable_inline {
    box-sizing: border-box !important;
    min-height: 18.75rem !important;
    border-radius: 1.25rem !important;
    border: 1px solid #e6e7f3 !important;
    padding: 1rem 2rem !important;
    margin-top: 0.5rem !important;
    max-width: calc(100vw - 2.5rem) !important;
  }

  .ck-focused {
    border: 1px solid var(--wzb-bo-primary) !important;
    box-shadow: none !important;
    outline: #e6e7f3 auto 0.0625rem !important;
    outline-offset: 0.3125rem !important;
  }

  .ck-toolbar {
    border-radius: 1.25rem !important;
    border: 1px solid #e6e7f3 !important;
    padding: 0 2rem !important;
    max-width: 99% !important;
    position: sticky !important;
    top: calc(var(--header-row) + var(--header-gap));
  }

  .ck-toolbar_vertical {
    border-radius: 0 !important;
    padding: 0 !important;
  }

  .ck-list-styles-dropdown > .ck-dropdown__panel > .ck-toolbar {
    border-radius: 0 !important;
    padding: 0 !important;
  }

  .ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_sw {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }

  &--error {
    .ck-editor__editable_inline {
      border: 1px solid #ce3f43 !important;
    }
  }
}
