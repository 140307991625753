@use '@angular/material' as mat;
@use 'material/variables' as variables;

.mat-mdc-list-option {
  min-height: 3rem;
  height: unset !important;

  .mat-ripple {
    display: none;
  }

  .mdc-checkbox {
    width: 3.25rem !important;
    height: 1.875rem !important;

    .mdc-checkbox__background {
      svg {
        display: none;
      }
    }

    .mdc-checkbox__native-control {
      + .mdc-checkbox__background {
        display: block;
        position: relative;
        width: 3.25rem !important;
        height: 1.875rem !important;
        border-radius: 1rem !important;
        background-color: #aaa !important;
        transition: background-color 100ms linear;
        border: none;
        top: 0;
        left: 0;

        &::after {
          content: '';
          position: absolute;
          top: 50% !important;
          transform: translateY(-50%);
          left: 0.625rem !important;
          width: 0.25rem !important;
          height: 1.25rem !important;
          border-left: none;
          border-bottom: none;
          border-radius: 0.5rem;
          background-color: white;
          opacity: 1;
          transition:
            left 100ms linear,
            width 100ms linear,
            border-radius 100ms linear;
        }
      }

      &:checked {
        /* stylelint-disable-next-line selector-max-specificity */
        + .mdc-checkbox__background {
          background-color: var(--wzb-bo-primary) !important;

          /* stylelint-disable-next-line selector-max-specificity */
          &::after {
            left: 1.75rem !important;
            width: 1.25rem !important;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .mdc-list-item__primary-text {
    font-size: 0.875rem;
  }

  &.cdk-drag-preview {
    background-color: white;

    .mdc-list-item-content {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      padding: 0 1rem;
      height: 100%;
    }
  }

  &.cdk-drag-placeholder {
    opacity: 0.25;
  }

  .cdk-drop-list-dragging &.cdk-drag {
    transition: transform 100ms linear;
  }

  &.cdk-drag-animating {
    transition: transform 75ms linear;
  }
}

.mat-mdc-list-option.isDisabled {
  /* stylelint-disable-next-line selector-type-no-unknown */
  .mdc-checkbox {
    display: none;
  }
}
