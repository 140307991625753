/* stylelint-disable selector-max-specificity, max-nesting-depth, no-descending-specificity, unit-disallowed-list */
.mat-paginator {
  .mat-paginator-range-actions {
    margin-top: -0.5rem;
  }
  .mat-icon-button {
    height: 32px;
    width: 32px;
    font-size: 0.625rem;
  }

  .mat-paginator-icon:hover {
    fill: #0062ff;
  }
  .mat-focus-indicator:hover {
    border: solid 0.0625rem #0062ff;
    color: black;
    border-radius: 0.5rem;
  }
  .mat-focus-indicator {
    border: solid 0.0625rem #d4d3d2;
    color: black;
    border-radius: 0.5rem;
    margin-right: 0.5rem;
  }

  .mat-form-field.mat-form-field-appearance-fill {
    .mat-form-field-wrapper {
      padding-bottom: 0.5rem;
      .mat-form-field-flex {
        padding: 0 0.5rem 0 0.625rem;
        border-radius: 0.5rem;
        .mat-form-field-infix {
          padding-bottom: 0;
          border-top-width: calc(0.75rem);
        }
      }
    }
  }
  .mat-select-panel-wrap {
    flex-basis: 100%;
    margin-top: 1rem;
    border-radius: 0.625rem;
    box-shadow: 0 0.5rem 1rem rgba(96 97 112 / 16%);
    background-color: white;
    .mat-option-text {
      padding-right: 5rem;
    }
  }
}

.mat-mdc-paginator {
  background-color: unset !important;
}
