.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* stylelint-disable selector-max-specificity */
.cdk-drop-list-dragging .list-item:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);

  .service-bundle__logo {
    opacity: 0.5;
  }
}

.cdk-drag-preview {
  box-shadow:
    0 0.3rem 0.3rem -0.1875rem rgba(0 0 0 / 2%),
    0 0.5rem 0.6rem 0.06rem rgba(0 0 0 / 14%);
}

.drag-icon:hover {
  cursor: grab !important;
}
