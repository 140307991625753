.mat-tab-link {
  color: var(--wzb-bo-primary) !important;
  font-size: 0.8125rem;
  text-decoration: none !important;
}
.mat-tab-link.mat-tab-label-active {
  opacity: 1 !important;
  font-weight: 600;
}

.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: var(--wzb-bo-primary);
}
