.mat-mdc-autocomplete-panel {
  border-radius: 20px;

  $box-shadow-color: rgba(96 97 112 / 16%);

  &:not([class*='mat-elevation-z']) {
    box-shadow: 0 0.5rem 1rem $box-shadow-color;
  }

  .mat-mdc-option {
    line-height: 2.25;
    height: 2.25em;
    min-height: unset;
    font-size: 0.875rem;
    padding: 0 0.5rem !important;

    &:hover {
      background: transparent !important;
      color: #4b45b2;
    }

    .mdc-list-item__primary-text {
      border-radius: 8px;
      width: 100%;
      padding: 0 0.5rem;

      &:hover {
        background-color: #e1e0f9;
      }
    }

    .mat-pseudo-checkbox {
      display: none;
    }
  }
}
