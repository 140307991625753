.mat-menu-content {
  padding: 0.5rem;
}

.mat-menu-panel {
  border-radius: 12px !important;
  border: 1px solid var(--wzb-bo-border-color);
  box-shadow: 0 0.625rem 3rem rgba(0 0 0 / 13%);
}

.mat-menu-item .mat-icon-no-color {
  color: inherit !important;
}
